import avax from "assets/img/avax.png";
import bsc from "assets/img/bsc.png";
import eth from "assets/img/eth.png";
import polygon from "assets/img/polygon.png";
import ftm from "assets/img/ftm.png";
export const currentProjects = [
  {
    name: "Crypto Unicorns",
    token: "RBW",
    tokenURI: require("assets/img/unicorn_logo.png").default,
    backgroundURI: require("assets/img/unicorn_banner.png").default,
    link: "https://cryptounicorns.vault.inc",
    chain: polygon,
    chainId: 137,
    analytics_id: "cryptounicorn",
  },
  {
    name: "BlockchainSpace",
    token: "GUILD",
    tokenURI: require("assets/img/GUILD.png").default,
    backgroundURI: require("assets/img/Guild_banner.png").default,
    link: "https://blockchainspace.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id: "blockchainspace",
  },
  {
    name: "BlockchainSpace (BSC)",
    token: "GUILD",
    tokenURI: require("assets/img/GUILD.png").default,
    backgroundURI: require("assets/img/Guild_banner.png").default,
    link: "https://bsc.blockchainspace.vault.inc",
    chain: bsc,
    chainId: 56,
    analytics_id: "blockchainspace_bsc",
  },
  {
    name: "Lootex",
    token: "LOOT",
    tokenURI: require("assets/img/lootex_logo.png").default,
    backgroundURI: require("assets/img/lootex_banner.jpeg").default,
    link: "https://lootex.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id: "lootex",
  },
  {
    name: "Lootex (BSC)",
    token: "LOOT",
    tokenURI: require("assets/img/lootex_logo.png").default,
    backgroundURI: require("assets/img/lootex_banner.jpeg").default,
    link: "https://bsc.lootex.vault.inc",
    chain: bsc,
    chainId: 56,
    analytics_id: "lootex_bsc",
  },
  {
    name: "KYOKO",
    token: "KYOKO",
    tokenURI: require("assets/img/kyoko.png").default,
    backgroundURI: require("assets/img/kyoko_background.jpeg").default,
    link: "https://kyoko.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id: "kyoko",
  },
  {
    name: "XY Finance",
    token: "XY",
    tokenURI: require("assets/img/xy_logo.png").default,
    backgroundURI: require("assets/img/xy_banner.jpeg").default,
    link: "https://xy.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id: "xyfinance",
    totop: true,
    extraReward: [require("assets/img/fnc_logo.png").default],
  },
  {
    name: "XY Finance (Polygon)",
    token: "XY",
    tokenURI: require("assets/img/xy_logo.png").default,
    backgroundURI: require("assets/img/xy_banner.jpeg").default,
    link: "https://polygon.xy.vault.inc",
    chain: polygon,
    chainId: 137,
    analytics_id: "xyfinance_polygon",
    totop: true,
    extraReward: [require("assets/img/unicorn_logo.png").default],
  },
];

export const upcomingProjects = [
  {
    name: "MetaOne",
    token: "MetaOne",
    tokenURI: require("assets/img/metaonegg_logo.png").default,
    backgroundURI: require("assets/img/metaonegg_banner.jpeg").default,
    link: "https://twitter.com/MetaoneGG",
  },
  {
    name: "ArcadeLand",
    token: "ArcadeLand",
    tokenURI: require("assets/img/arcadeland_logo.png").default,
    backgroundURI: require("assets/img/arcadeland_banner.jpeg").default,
    link: "https://twitter.com/playarcadeland",
  },
  {
    name: "YIN Finance",
    token: "YIN",
    tokenURI: require("assets/img/yin_logo.png").default,
    backgroundURI: require("assets/img/yin_banner.jpeg").default,
    link: "https://twitter.com/YINFinance",
  },
  {
    name: "Walken",
    token: "WLKN",
    tokenURI: require("assets/img/walken_logo.png").default,
    backgroundURI: require("assets/img/walken_banner.jpeg").default,
    link: "https://twitter.com/walken_io",
  },
  {
    name: "YIELD GUILD GAMES",
    token: "YGG",
    tokenURI: require("assets/img/ygg_logo.png").default,
    backgroundURI: require("assets/img/ygg_banner.png").default,
    link: "https://twitter.com/YieldGuild",
  },
  {
    name: "Gametaverse",
    token: "Gametaverse",
    tokenURI: require("assets/img/gametaverse.png").default,
    backgroundURI: require("assets/img/gametaverse_background.jpeg").default,
    link: "https://twitter.com/GametaverseDAO",
  },
  {
    name: "Highstreet",
    token: "HIGH",
    tokenURI: require("assets/img/highstreet.png").default,
    backgroundURI: require("assets/img/highstreet_banner.png").default,
    link: "https://twitter.com/highstreetworld",
  },
  {
    name: "Illiquid DAO",
    token: "JPEGS",
    tokenURI: require("assets/img/IlliquidDAO.png").default,
    backgroundURI: require("assets/img/Illiquiq_banner.png").default,
    link: "https://twitter.com/illiquidDao",
  },
  {
    name: "Neftify",
    token: "Neftify",
    tokenURI: require("assets/img/neftify_logo.png").default,
    backgroundURI: require("assets/img/neftify_banner.jpeg").default,
    link: "https://twitter.com/neftify",
  },
  {
    name: "Dracoo Master",
    token: "Dracoo Master",
    tokenURI: require("assets/img/dracoo_logo.png").default,
    backgroundURI: require("assets/img/dracoo_banner.jpeg").default,
    link: "https://twitter.com/Dracoo_Master",
  },
  {
    name: "Avarik Saga Universe",
    token: "AVRK",
    tokenURI: require("assets/img/avrk_logo.png").default,
    backgroundURI: require("assets/img/avrk_banner.jpeg").default,
    link: "https://twitter.com/avariksaga",
  },
  {
    name: "ARCADE Inc",
    token: "ARCADE",
    tokenURI: require("assets/img/arcade_logo.jpeg").default,
    backgroundURI: require("assets/img/arcade_banner.jpeg").default,
    link: "https://arcade.inc/",
  },
  {
    name: "QUOTH",
    token: "QUOTH",
    tokenURI: require("assets/img/quoth_logo.png").default,
    backgroundURI: require("assets/img/quoth_banner.jpeg").default,
    link: "https://twitter.com/Quoth_ai",
  },
  {
    name: "ARCHE",
    token: "ARCHE",
    tokenURI: require("assets/img/arche_logo.png").default,
    backgroundURI: require("assets/img/arche_banner.jpeg").default,
    link: "https://twitter.com/Arche_Network",
  },
  {
    name: "Metabase",
    token: "Metabase",
    tokenURI: require("assets/img/metabase_logo.png").default,
    backgroundURI: require("assets/img/metabase_banner.jpeg").default,
    link: "https://twitter.com/Metabase_gg",
  },
  {
    name: "ForthBox",
    token: "FBX",
    tokenURI: require("assets/img/fbx_logo.png").default,
    backgroundURI: require("assets/img/fbx_banner.jpeg").default,
    link: "https://twitter.com/forthbox",
  },
  {
    name: "DeNet",
    token: "DeNet",
    tokenURI: require("assets/img/denet_logo.png").default,
    backgroundURI: require("assets/img/denet_banner.jpeg").default,
    link: "https://twitter.com/denetpro",
  },
  {
    name: "League Of Ancients",
    token: "LOA",
    tokenURI: require("assets/img/loa_logo.png").default,
    backgroundURI: require("assets/img/loa_banner.jpeg").default,
    link: "https://twitter.com/LOAOfficialMOBA",
  },
  {
    name: "YGGSEA",
    token: "SEA",
    tokenURI: require("assets/img/sea.png").default,
    backgroundURI: require("assets/img/ygg-sea.jpeg").default,
    link: "https://twitter.com/yggsea",
    chain: eth,
    chainId: 1,
    analytics_id: "yggsea",
  },
];

export const nftProjects = [
  {
    name: "Zeeverse",
    token: "ZEE LAND",
    tokenURI: require("assets/img/zee_mask_logo.png").default,
    backgroundURI: require("assets/img/zee_land_banner.png").default,
    link: "https://zeeverse-land.vault.inc/",
  },
  {
    name: "Zeeverse",
    token: "ZEE MASK",
    tokenURI: require("assets/img/zee_mask_logo.png").default,
    backgroundURI: require("assets/img/zee_mask_banner.png").default,
    link: "https://zeemask.vault.inc/",
  },
  {
    name: "Zeeverse",
    token: "ZEE EGG",
    tokenURI: require("assets/img/zeev_logo.png").default,
    backgroundURI: require("assets/img/zeev_banner.jpeg").default,
    link: "https://zeeverse.vault.inc/",
  },
  {
    name: "Fitzon",
    token: "FIT",
    tokenURI: require("assets/img/fitzon_logo.png").default,
    backgroundURI: require("assets/img/fitzon_banner.jpg").default,
    link: "https://fitzon.vault.inc/",
  },
];
