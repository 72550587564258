import React, { useEffect,useState }  from "react";
import {Grid, useMediaQuery, Modal, Checkbox} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import createTheme from "../jss/appTheme";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import avax from "assets/img/avax.png";
import bsc from "assets/img/bsc.png";
import eth from "assets/img/eth.png";
import polygon from "assets/img/polygon.png";
import ftm from "assets/img/ftm.png";
import { currentProjects,upcomingProjects, nftProjects } from "features/configure";
import Social from "./Social";
import axios from "axios"
import BigNumber from "bignumber.js";
import CustomTable from "components/CustomTable/CustomTable.js";
import _, { isNumber } from "lodash";

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';


let contributed = 0;


const renderChain = (row) => {
  console.log(row);
  return <img src={row.chain} className="smIcon"/>
}

const detailModifiy = (originalDetail) => {

  return currentProjects.map((row, index) => {
    let minArray = [];
    let maxArray = [];
    let totalTVL = 0;
    

    if(originalDetail[row.analytics_id]){
      totalTVL = originalDetail[row.analytics_id].totalTvl;
      
      if(originalDetail[row.analytics_id].singlePoolAPRMin && !isNaN(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, ''))) && parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, ''))!=0 && isFinite(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, '')))){
        minArray.push(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].lpPoolAPRMin && !isNaN(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, ''))) && parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, ''))!=0 && isFinite(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, '')))){
        minArray.push(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].singlePoolAPRMax && !isNaN(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMax.replace(/,/g, ''))) && isFinite(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMax.replace(/,/g, '')))){
        maxArray.push(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMax.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].lpPoolAPRMax && !isNaN(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, ''))) && isFinite(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, '')))){
        maxArray.push(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, '')));
      }
    } 


    let aprMin = _.min(minArray);
    let aprMax = _.max(maxArray);

    if(isNaN(aprMin) || !isFinite(aprMin)){
      aprMin = 0;
    }
    
    if(isNaN(aprMax) || !isFinite(aprMax)){
      aprMax = 0;
    }

    
    return {
      icon:<img src={row.tokenURI} className="icon" />,
      project:row.name,
      id:row.analytics_id,
      totalTVL:totalTVL,
      minAPR:aprMin,
      maxAPR:aprMax,
      chain:originalDetail[row.analytics_id]?renderChain(row):"-",
      linkurl:row.link,
      chainId:row.chainId,
      tokenPrice:originalDetail[row.analytics_id]?parseFloat(originalDetail[row.analytics_id].tokenPrice).toFixed(4):"-",
      totop:row.totop
    }
    })
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const LandingContent = () => {
  const [tvl, setTvl] = useState(0);
  const [reward, setReward] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [detail, setDetail] = useState([]);
  const [sortBy, setSortBy] = useState("Total Value Locked");
  const [sortedDetail, setSortedDetail] = useState({});
  const theme = createTheme(true);
  const computer = useMediaQuery(theme.breakpoints.up('sm'));
  const [search, setSearch] = useState("");
  const [alert, setAlert] = useState(true);
  const [agree, setAgree] = useState(false);
  const [agree2, setAgree2] = useState(false);
  


  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  const sortDetail = (d,s="Total Value Locked") => {
    // console.log(d);
    const kMapping = {
      "Total Value Locked":"totalTVL",
      "Projects":"project",
      "Base Reward APY":"maxAPR",
      "Chain":"chainId",
      "$":"tokenPrice"
    }
    if (s=="Projects") {
      setSortedDetail(_.orderBy(d,[kMapping[s]],["aesc"]));
    } else if(s=="Chain"){
      setSortedDetail(_.orderBy(d,["totop",kMapping[s],"totalTVL"],["aesc","aesc","desc"]));
    }
    else {
      setSortedDetail(_.orderBy(d,["totop",kMapping[s],"totalTVL"],["aesc","desc"]));
    }
    
  }

  useEffect(async() => {
    let res = await axios.get("https://analytics.vault.inc/api/stats")
    if(res.data){
      setTvl(res.data.totalTvl);
      setReward(res.data.totalClaimed);
      setDetail(detailModifiy(res.data.stats));
    }
    
    const id = setInterval(async() => {
      let res = await axios.get("https://analytics.vault.inc/api/stats")
      if(res.data){
        setTvl(res.data.totalTvl)
        setReward(res.data.totalClaimed)
        setDetail(detailModifiy(res.data.stats));
      }
    }, 10000);
    return () => clearInterval(id);
}, []);

  useEffect(() => {
    sortDetail(detail,sortBy);
  }, [detail,sortBy]);
  return (
    <>
      <Container style={{ paddingTop: "10vh", lineHeight: 0.8 }}>
      <Modal
      style={{display: "flex",
      alignItems: "center",
      justifyContent: "center"}}
      open={alert}
      >
        <div style={{minWidth: 300,
        maxWidth:"60%",
        maxHeight:"85%",
        overflowY:"scroll",
    textAlign:"center",
    color:"white",
    backgroundColor: "#242424",
    borderRadius:"25px",
    padding: "55px 25px",}}>
          <h1>Attention</h1>
          <div style={{margin:"40px 0", fontSize:16}}>
          <h3>
          Acknowledgements
          </h3>
You accept that the use of the Vault.inc Protocol is entirely at your own risk, and that doing so could lead to partial or full loss of deposits. You take full responsibility for your use of the Vault.inc, and acknowledge that you use it on the basis of your own enquiry <br/>
          <h3>Citizenship Amendment</h3>
          The Vault.inc governance is not available for US citizen or national, or if you act for a company that is domiciled in the US or majority owned by US citizens or US companies.
The same applies to citizens or companies that are subject to further sanctions, e.g. from the Russian Federation or Belarus.
          </div>
          
          <Checkbox style={{color:"white"}} {...label} checked={agree} color="red"
  onChange={(e)=>{
    setAgree(e.target.checked);
  }}  /> I confirm that I have read, understand and accept the Terms of Use and the Risks Statement <br/>
          <Checkbox style={{color:"white"}} {...label} checked={agree2}  color="red" onChange={(e)=>{
    setAgree2(e.target.checked);
  }}   /> I confirm that I do not fall under any of these exclusions <br/>


          <Button style={{fontSize:"2vw", maxWidth:"60%", minWidth:"200px"}} color="secondary" disabled={!agree || !agree2} onClick={()=>{
            setAlert(false)
            }} 
           >Yes, I understand the risk. </Button>
          <br/>
          Audits: 
          <a href="https://gateway.pinata.cloud/ipfs/QmXyKsEeDe1WzDEuT6v95oUdzVLr2B2xRiV51EamsizroV?preview=1" target={"_blank"}>Peckshield</a>, 
          <a href="https://gateway.pinata.cloud/ipfs/QmZLyPfGXNk4nsBKuSYeMndisV6mm3L91bX8TngLQzttGz?preview=1" target={"_blank"}>Certik</a>, and 
          <a href="https://certificate.quantstamp.com/full/vault-inc" target={"_blank"}>Quantstamp</a>
        </div>
      </Modal>
     
        <Grid container justifyContent="center" alignItems="flex-end" spacing={3} style={{marginTop:"180px"}}>
          <Grid item xs={12} sm={1}/>
          <Grid item xs={12} sm={2} style={{textAlign:"center"}}>
            <img src={require("assets/img/logo.png").default} style={{height:180,objectFit:"contain"}}/>
          </Grid>
          <Grid item xs={12} sm={5} >
            <span style={{float:"right", marginBottom:10}}><Social/></span>
            <div className="subheader" style={{textAlign:"left", marginTop:0, paddingTop:0}}>Vault.Inc</div>
           
            <div style={{fontSize:23.5,lineHeight:1.3}}>Empowers project with staking & locking mechanism, plus leaderboard and badge system.<br/>
            <div style={{color:"#2392EC"}}>Contact us on twitter for listing inquiry.</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
              <Button size="lg" color="primary" fullWidth
                onClick={()=>{
                  window.scrollTo(0,950)
                  }}>Explore</Button>
                <Button size="lg" color="secondary" fullWidth
                onClick={()=>{
                  window.open("https://twitter.com/vaultinc_")
                  }}>Listing Inquiry</Button>
          </Grid>
          <Grid item xs={12} sm={1}/>
        </Grid>
        <img src={require("assets/img/headerTop.png").default} style={{width:"100%", marginBottom:"-150px", marginTop:150}}/>
        <Grid container justifyContent="center" alignItems="center" spacing={3} >
          
          <Grid item xs={12} sm={4}>
            <div  style={{}}>
          
              <div style={{marginTop:20,textAlign:"center"}}>
                  <img
                    src={eth}
                    className="chain"
                  />
                  <img
                    src={bsc}
                    className="chain"
                  />
                  <img
                    src={avax}
                    className="chain"
                  />
                  <img
                    src={polygon}
                    className="chain"
                  />
                  <img
                    src={ftm}
                    className="chain"
                  />
              </div>
              <div className="valueCard">Supported Chains</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>

            <div className="header">${new BigNumber(parseFloat(tvl+contributed)).toFormat(0)}</div>
            <div className="valueCard">Total Value Locked
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>

            <div className="header">${new BigNumber(parseFloat(reward).toFixed(0)).toFormat(0)}</div>
            <div className="valueCard">Total Rewards Claimed
            </div>
          </Grid>

         
        </Grid>
      
      </Container>
  
      <div
        style={{
          marginTop: 50,
          maxWidth: 1200,
          margin: "80px auto",
          textAlign: "center",
          position: "relative",
          padding:10,
        }}
      >
        {/* <div style={{ marginBottom: 150 }}>
          <h1 className="header">Total Locked Value</h1>
          <h1 className="header">${1000000000}</h1>
        </div> */}
         <div style={{float:"right"}}> 
          <CustomOutlinedInput
                value={search}
                style={{width:250}}
                placeholder="search"
                onChange={changeInputValue}
              />
        </div>
        <h1 className="header" style={{fontSize:32, textAlign:'left'}}>Current Projects</h1>
       
        <Grid container >
        <Grid item xs={12} md={4} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Projects");
        }}>
         {sortBy == "Projects"? "Project ↓":"Project"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Total Value Locked");
        }}>
        {sortBy == "Total Value Locked"? "Total Value Locked ↓":"Total Value Locked"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Base Reward APY");
        }}>
        {sortBy == "Base Reward APY"? "Base Reward APY ↓":"Base Reward APY"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader">
        Extra Rewards
        </Grid>
        <Grid item xs={12} md={1} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Chain");
        }}>
        {sortBy == "Chain"? "Chain ↓":"Chain"}
        </Grid>
          {_.map(sortedDetail,(data) => {
            const project = _.find(currentProjects,{"analytics_id":data.id})
            if( search && !data.project.toLowerCase().includes(search.toLowerCase())
            && !project.token.toLowerCase().includes(search.toLowerCase())){
              return
            }
            return (
              <Grid item xs={12} md={12}>
                  <div
                    style={{
                      margin:1,
                      background: `linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)`,
                      borderRadius:20,                      
                      position: "relative",
                      cursor: "pointer",
                      fontSize:18.6,
                      fontWeight:500,
                      color:"white"
                    }}
                    onClick={() => window.open(data.linkurl)}
                  >
                    
                    <img src={project.backgroundURI} style={{ position: "absolute", left: 0, top:0, width:computer?"30%":"100%",height:"100%", objectFit:"cover"
                    ,borderRadius:computer?"20px 0px 0px 20px":20,zIndex:5, opacity:0.5}}/>
                    <Grid container style={{zIndex:10, position: "relative", padding:15,}} alignItems="center">
                      <Grid item xs={12} sm={4} style={{textAlign:"left"}}>
                      <Grid container alignItems="center">
                      <Grid item xs={3} style={{textAlign:"center"}} >
                      <img src={project.tokenURI} className="icon" />
                      </Grid>
                      <Grid item xs={9  }>
                      <div style={{fontWeight:900, fontSize:20}}>
                        {project.token}
                      </div>
                      <div style={{fontSize:14}}>{data.project}</div>
                      </Grid>
                      </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      ${new BigNumber(data.totalTVL).toFormat(0)} 
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      {data.minAPR} - {data.maxAPR} %
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      {project.extraReward ? project.extraReward.map(img => {
                        return <img src={img} className="icon"></img>
                      }):"Soon"}
                      </Grid>
                      <Grid item xs={12} sm={1} >     
                      {data.chain}
                      </Grid>
                      <Grid item xs={12} sm={1} >     
                        <DoubleArrowIcon/>
                      </Grid>
                    </Grid>
              
                    
                  </div>
              </Grid>
            );
          })}
        </Grid>
        <img src={require("assets/img/headerTop.png").default} style={{width:"100%", marginBottom:"-200px", marginTop:100}}/>
        <h1 className="header" style={{fontSize:32, textAlign:"center"}}>NFT Staking</h1>
        <Grid container spacing={1}>
          {nftProjects.map((data) => {
            return (
              <Grid item xs={12} md={4}>
                <Link to="">
                
                  <div
                    className="projectCard"
                    style={{
                      background: `url(${data.backgroundURI})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      backgroundBlendMode: "multiply",
                      backgroundSize: "cover",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(data.link)}
                  >
                    <img src={data.tokenURI} className="smIcon" />
                    <span style={{fontSize:16}}>{data.token}</span>
                    <div style={{ position: "absolute", bottom: 15,right:15, width:200, textAlign:"right"}}>
                      <span className="cardHeader" style={{}}>{data.name}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <img src={require("assets/img/headerTop.png").default} style={{width:"100%", marginBottom:"-200px", marginTop:100}}/>
        <h1 className="header" style={{fontSize:32, textAlign:'left',textAlign:"center"}}>Upcoming Projects</h1>
        <Grid container spacing={1}>
          {upcomingProjects.map((data) => {
            return (
              <Grid item xs={12} md={3}>
                <Link to="">
                
                  <div
                    className="projectCard"
                    style={{
                      background: `url(${data.backgroundURI})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      backgroundBlendMode: "multiply",
                      backgroundSize: "cover",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(data.link)}
                  >
                    <img src={data.tokenURI} className="smIcon" />
                    <span style={{fontSize:16}}>{data.token}</span>
                    <div style={{ position: "absolute", bottom: 15,right:15, width:200, textAlign:"right"}}>
                      <span className="cardHeader" style={{}}>{data.name}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default LandingContent;
