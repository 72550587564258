import React, { memo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Box from '@material-ui/core/Box';
import styles from "./styles";
import _, { set } from "lodash";

const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  type = "table",
  leftText = { 0: true },
  style = {},
  selectedRow = [],
  sortDataFunction,
  rowSelected
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");


  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;
  if (type == "outlined") {
    titleClass = classes.outlinedTitle;
    valueClass = classes.outlinedValue;
  }
  if (type == "column") {
    titleClass = classes.columnTitle;
    valueClass = classes.columnValue;
  }

  return (
    <TableContainer className={classes.card}>
      <Table
        style={{
          borderCollapse: "separate",
          borderSpacing: "0 1em",
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {

              return (
                <TableCell
                  key={index}
                  align={leftText[index] == true ? "left" : "center"}
                  className={`${classes.title} ${titleClass}`}
                  style={{ ...style }}
                >
                  <TableSortLabel
                    active={orderBy === header}
                    className={`tableTitle`}
                    style={{color:"white"}}
                    direction={orderBy === header ? order : 'dec'}
                    disabled={index == 0 || index == 5 }
                    onClick={()=>{
                      setOrderBy(header);
                      sortDataFunction(header);
                      }
                    }
                  >
              {header}
              
            </TableSortLabel>
                  
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(contents, (row, index) => (
            
            <TableRow
              key={index}
              style={{
                borderRadius: 10,
                marginBottom: 10,
                cursor:"pointer",
                position:"relative"
              }}
              onClick={()=>{
                rowSelected(index)
              }}
            >
             
              {row &&
                row.map((r, rIndex) => (
                  <TableCell
                    key={rIndex}
                    align={leftText[rIndex] == true ? "left" : "center"}
                    className={`${classes.value} ${valueClass} ${
                      selectedRow.includes(index) ? classes.selected : ""
                    }`}
                    style={{ ...style }}
                  >
                  {selectedRow.includes(index)&& rIndex==0 && 
                  <span style={{
                    padding:"1px 7px", background:"red",borderRadius:"10px 0 10px 0px",
                    fontSize:10,
                    position:"absolute", left:0, top:0
                     }}>Copperlaunch Live</span>}
                    {r}
                  </TableCell>
                ))}
                
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTable);
