import React from "react";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Chip from "@material-ui/core/Chip";
import MenuItems from "./MenuItems";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/logo.png";

const drawerWidth = "60vw";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  toolbar: {
  
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background:"rgba(0, 0, 0, 0.4)",
    backdropFilter: 'blur(96.5366px)',
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background:"linear-gradient(90deg, #0B1726 3.49%, #07090D 39.49%, #06070A 66.56%, #0B1726 96.28%)",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  gradientBtn: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
}));

const LandingHeader = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Hidden mdUp>
          <Toolbar className={classes.toolbar}>
            <Grid justifyContent="space-between" alignItems="center" container>
              <Grid item>
                <div style={{ color: "white", fontWeight:700, fontSize:22,padding:5}}>
                <img
                  src={logo}
                  style={{
                    height: "20px",
                    marginRight: "10px",
                    objectFit: "contain",
                    verticalAlign:"middle"
                  }}
                />
                Vault.Inc
              </div>
              </Grid>

              <Grid item>
                {/* <IconButton
                  aria-label="open drawer"
                  style={{ color: "white" }}
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Drawer
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !mobileOpen && classes.drawerPaperClose
              ),
            }}
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <div style={{ textAlign: "center" }}>
              <List>
                {/* <MenuItems handleDrawerToggle={handleDrawerToggle} /> */}
              </List>
            </div>
          </Drawer>
        </Hidden>
       </AppBar>
      <Hidden smDown>
      <AppBar className={classes.appBar}>
       <Toolbar className={classes.toolbar}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-end"
        container
        style={{ zIndex: 500,maxWidth:1200, margin:"0 auto", height:130 }}
      >
        <Grid item>
          <a href="/">
  
            <div style={{ color: "white", fontWeight:700, fontSize:33,padding:20}}>
              <img
                src={logo}
                style={{
                  height: "37px",
                  marginRight: "15px",
                  objectFit: "contain",
                  verticalAlign:"middle"
                }}
              />
              Vault.Inc
          
            </div>
    
          </a>
        </Grid>

        <Grid item>{/* <MenuItems /> */}</Grid>
        <Grid ></Grid>
      </Grid>
      </Toolbar>
        </AppBar>
      </Hidden>
    </div>
  );
};

export default LandingHeader;
