import React from "react";
import discordIcon from "assets/img/discord.png";
import twitterIcon from "assets/img/twitter.png";
import githubIcon from "assets/img/github.png";

const Social = ({

}) => {
 
  return (
    <div >
      <a href="https://twitter.com/vaultinc_" target="_blank">
              <img
                src={twitterIcon}
                className="smSocialIcon"
              />
            </a>
            <a href="https://github.com/vault-tec-team" target="_blank">
              <img
                src={githubIcon}
                className="smSocialIcon"
              />
            </a>
            <a href="https://discord.com/invite/5eucBfYJrD" target="_blank">
              <img
                src={discordIcon}
                className="smSocialIcon"
              />{" "}
            </a>
            {/* <a href="https://certificate.quantstamp.com/full/vault-inc" target="_blank">
              <img
                src={quantstampIcon}
                className="smSocialIcon"
              />{" "}
            </a> 
             <a href="" target="_blank">
              <img
                src={mediemIcon}
                className="smSocialIcon"
              />{" "}
            </a>
            <a href="" target="_blank">
              <img
                src={gitbookIcon}
                className="smSocialIcon"
              />
            </a>
            */
            }
           
    </div>
  );
};


export default Social;
