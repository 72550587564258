import React, { useEffect,useState }  from "react";
import styled from "styled-components";
import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary, useMediaQuery,Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import createTheme from "./jss/appTheme";
import axios from "axios"
import BigNumber from "bignumber.js";
import { currentProjects } from "features/configure";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button";
// import { Slide } from 'react-slideshow-image';
import _, { isNumber } from "lodash";
import { Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Social from "./components/Social";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color:"#FFFFFF",
    fontSize:21,
    textAlign:"left",
    padding:12
  },
  accordion: {
    backgroundColor: "transparent",
    border: "1px solid white",

    boxShadow: "none",
    "&.Mui-expanded": {
      marginTop: 0,
    },
    "&.MuiAccordion-root:before": {
      height: 0
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    }
  },
  switchableTitle: {
    borderRadius: 10,
    "&.Mui-expanded": {
      borderRadius: "10px 10px 0 0",
    },

  },
}));


const LandingPage = () => {
  const theme = createTheme(true);
  const classes = useStyles();
  const [reward, setReward] = useState(0);
  const { t } = useTranslation();
  const [tvl, setTvl] = useState(0);
  let contributed = 0;
  const computer = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(async() => {
    let res = await axios.get("https://analytics.vault.inc/api/stats")
    if(res.data){
      setTvl(res.data.totalTvl);
      setReward(res.data.totalClaimed);
    }
    
    const id = setInterval(async() => {
      let res = await axios.get("https://analytics.vault.inc/api/stats")
      if(res.data){
        setTvl(res.data.totalTvl)
        setReward(res.data.totalClaimed)

      }
    }, 10000);
    return () => clearInterval(id);
}, []);
const strategies = [
  {
    title:"Stake & Lock",
    description:"Staking and locking strategies designed with veCRV-like model and capital efficiency in mind. Dynamic reward program that can be adjusted according to market condition. We also support any EVM chains, and snapshot governance ready to go once deployed.",
    url: require('assets/img/strategy1.png').default
  },
  {
    title:"NFT",
    description:"NFT staking enables projects to incentive holders to stake and farm rewards that could be both ERC721 or ERC20.\n Furthermore, vault.inc also support both “soft” and “hard” staking. Contact us to learn more about both options.",
    url: require('assets/img/strategy2.png').default
  },
  {
    title:"Bonding",
    description:"Protocol owned liquidity is a liquidity provision strategy pioneered by Olympus DAO to provide liquidity to tokens on DEX.\n Instead of relying on providing incentive to the market to provide liquidity to liquidity pools, the protocol owned liquidity model instead utilizes a “bonding” mechanism that doesn't create sell pressure.",
    url: require('assets/img/strategy3.png').default
  },
];
const tools = [
  {
    title:"Leaderboard",
    description:"Customizable campaigns to incentives users with rewards or NFTs. This is a great for protocols to collaborate with other projects. And it also helps projects during their public raise on copperlaunch. The leaderboard supports daily, weekly, monthly, and yearly snapshot.",
    url: require('assets/img/tool0.png').default
  },
  {
    title:"On-chain Analysis",
    description:"On-chain analysis viewable for both users and projects to always understand what is going on with the circulating supply, token emission, staking percentage, and locked token amount. Please let us know if you would like to see additional on-chain information.",
    url: require('assets/img/tool1.png').default
  },
  {
    title:"Marketing Exposures",
    description:"Leverage Vault.inc’s expertise and partners to bootstrap your community and create effective growth strategies. Every listed projects can cooperate and plan for reward strategies that would benefit both. Contact us to learn more.",
    url: require('assets/img/tool2.png').default
  },
];
const coppers = [
  {
    title:"Spot price without any buy/sell orders",
    description:"Due to the design of Balancer Liquidity Bootstrapping Pool (BLBP) on Copperlaunch, the token price would slowly decreases to the final end price if no one purchases the token.",
    url: require('assets/img/copper.png').default
  },
  {
    title:"",
    description:<>{"Vault.inc has supported different projects to launch successful public raise on copperlaunch. Each campaign is tailor-made for each project.\nHere are some examples:"}
    <div style={{marginTop:20}}><img src={require('assets/img/copperExamples.png').default}/></div></>,
    url: require('assets/img/copper1.png').default
  },

];

const properties = {
  duration: 10000,
  slidesToShow:computer?2:1,
  slidesToScroll:computer?2:1,
  autoplay:true,
  infinite: false,
  indicators: true,
};
const properties2 = {
  duration: 5000,
  slidesToShow:1,
  autoplay:true,
  infinite: true,
  arrows:false,
  scale: 1.4,
  indicators: true,
};
const properties3 = {
  duration: 5000,
  slidesToShow:1,
  autoplay:true,
  infinite: true,
  arrows:false,
  scale: 1.4,
};
let projectProperties = {
  duration: 5000,
  slidesToShow:computer?6:2,
  slidesToScroll:computer?4:2,
  autoplay:true,
  infinite: true,
  arrows:false,
  indicators: true,
};

const renderCards = (data)=>{
  return(
    data.map((slideImage, index)=> (
      <div className="card" key={index}>
        <Grid container>
          <Grid item xs={12} md={5} style={{padding:30}}>
            <div style={{fontSize:40, fontWeight:700, marginBottom:30 }}>{slideImage.title}</div>
            <div style={{fontSize:20, fontWeight:500}}>{slideImage.description}</div>
          </Grid>
          <Grid item xs={12} md={7}>
          <img src={slideImage.url} style={{width:"100%",height:"100%", objectFit:"contain",objectPosition:"50% 50%"}}/>
          </Grid>
        </Grid>
        
      </div>
    ))
  )
}

const renderCommunity =(title,icon, link)=>{
  return(
    <Grid item xs={12} md={2}>
    <a href={link} target="_blank">
      <div style={{border:"1px solid #2D9AEE", borderRadius:15, padding:"30px 25px",margin:10,textAlign:"center",
      background: 'linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)'}}>
      <img className="lgIcon" src={icon}/>
      <div style={{fontSize:20, fontWeight:500, margin:10,color:"white"}}>{title}</div>
    </div>
    </a>
    </Grid>
  )

}
  return (
    <>
        <div style={{padding:"40px 0", textAlign:"center", margin:"200px auto"}}>
            <div className="each-slide" style={{margin:"10px auto"}}>
              <div style={{textAlign:"center" }}>
                  <div style={{fontSize:31, fontWeight:600,color:"#2392EC"}}> Total Value Locked</div>
                  <div style={{fontSize:computer?101:40,background: "linear-gradient(0deg, #FFFFFF 30.83%, rgba(255, 255, 255, 0) 143.44%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    "background-clip": "text",
                    "text-fill-color": "transparent"}}>
                  ${new BigNumber(parseFloat(tvl+contributed)).toFormat(0)}
                  </div>
                </div>
                </div>
            
                <Link to={"/home"}><Button color="secondary" 
                style={{marginTop:25,background:"linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)"}}>Explore</Button></Link>
           
                <div style={{marginTop:25}}>
                  <Social/>
                </div>
         </div>
         <div style={{background: 'linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)',
            boxShadow: '0px 0px 37px rgba(33, 163, 236, 0.6)', padding:5}}>
            <div style={{maxWidth:1400, margin:"0 auto", marginTop:10}}>
                <Slide {...projectProperties}>
                {currentProjects.map((data) => {
              return (
                <div className="each-slide" style={{margin:"10px auto"}}>
                    <div
                      className="projectCard"
                      style={{
                        background: `url(${data.backgroundURI})`,
                        backgroundColor:"#373737",
                        backgroundPosition: "center",
                        height:90,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        backgroundBlendMode: "multiply",
                        backgroundSize: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(data.link)}
                    >
                      <Grid container alignItems="flex-start">
                        <Grid item xs={5}><img src={data.tokenURI} className="icon" /></Grid>
                        <Grid item xs={7}><span style={{fontSize:16, fontWeight:600, textOverflow:'ellipsis', width:"100%", display:'inline-block',overflow: 'hidden'}}>{data.name}</span></Grid>
                      </Grid>
                      
                     
                    </div>
                  </div>
              );
            })}
                </Slide>
                </div>
            </div>
          <Container style={{margin:"140px auto", textAlign:"center"}}>
 
          

          
            <div className="subheader">What is Vault.inc?</div>
            <div className="description">
            Vault.inc is a Staking-as-a-Service” platform that provides multiple staking strategies for both token and NFT projects. It supports every EVM-chain, dynamic reward systems, dual token rewards, leaderboard, on-chain analysis, and more. Vault.inc strives to work closely with it’s partners to support them.
            </div>
    

             <Grid container spacing={3} alignItems="center" style={{textAlign:"center"}}>
                  <Grid item xs={12} md={4} >
                    <img src={require("assets/img/method1.png").default} style={{height:370,objectFit:"contain"}}/>
                    <div style={{fontSize:30,fontWeight:600}}>Safty First</div>
                  </Grid>
      
                  <Grid item xs={12} md={4}>
                    <img src={require("assets/img/method2.png").default} style={{height:computer?370:250,objectFit:"contain"}}/>
                    <div style={{fontSize:30,fontWeight:600}}>Multi Chain</div>
                  </Grid>
           
                  <Grid item xs={12} md={4}>
                    <img src={require("assets/img/method3.png").default} style={{height:370,objectFit:"contain"}}/>
                    <div style={{fontSize:30,fontWeight:600}}>Reward</div>
                  </Grid>
              </Grid>
           
            <div >
              <div className="subheader">Vault Staking Strategies</div>
              <div className="description">
              Vault.inc supports multiple standard and customized staking strategies.
              </div>
              {renderCards(strategies)}
        
             </div>
             <div>
              <h1 className="subheader"  style={{marginTop:160,marginBottom:80}}>Vault Tools</h1>
              {renderCards(tools)}
       
              </div>

            <div>
              <h1 className="subheader"  style={{marginTop:160,marginBottom:80}}>How does Vault help with project’s IDO<br/> on Copperlaunch?</h1>
              {renderCards(coppers)}
         
             
            </div>
          <div >
            <h1 className="subheader" style={{marginTop:150,marginBottom:60}}>Audits</h1>
            <Grid container spacing={3} style={{ margin:"0 auto", textAlign:"center", padding:10}}>

              <Grid item xs={12} md={3}>
               <a href="https://certificate.quantstamp.com/full/vault-inc" target="_blank">
                  <img src={require("assets/img/audit1.png").default} style={{height:48,objectFit:"contain"}}/>
                </a>
              </Grid>
        
              <Grid item xs={12} md={3}>
                <a href="https://gateway.pinata.cloud/ipfs/QmZLyPfGXNk4nsBKuSYeMndisV6mm3L91bX8TngLQzttGz?preview=1" target="_blank">
                  <img src={require("assets/img/audit2.png").default} style={{height:48,objectFit:"contain"}}/>
                </a>
              </Grid>
              <Grid item xs={12} md={3}>
                <a href="https://gateway.pinata.cloud/ipfs/QmXyKsEeDe1WzDEuT6v95oUdzVLr2B2xRiV51EamsizroV?preview=1" target="_blank">
                  <img src={require("assets/img/audit3.png").default} style={{height:48,objectFit:"contain"}}/>
                </a>
              </Grid>
              <Grid item xs={12} md={3}>
                <a href="https://immunefi.com/bounty/blockchainspace/" target="_blank">
                  <img src={require("assets/img/audit4.png").default} style={{height:48,objectFit:"contain"}}/>
                </a>
              </Grid>
      
            </Grid>

            <Grid container spacing={3} style={{ margin:"200px auto",textAlign:"left" }}>
            <Grid item xs={12} md={1}/>
              <Grid item xs={12} md={5}>
                <div style={{padding:30}}>
                  <div style={{fontWeight:700,fontSize:32, marginBottom:40}}>Add your projects</div>
                  <div style={{fontWeight:500,fontSize:20}}>Are you part of a project and interested  in creating a staking strategies with us while gaining exposures and access to our network of projects and communities?</div>
                  <div style={{marginTop:30}}>
                    <a href="https://twitter.com/vaultinc_" target={"_blank"}><Button color="primary">Message us now!</Button></a></div>
                </div>
               
              </Grid>

              <Grid item xs={12} md={6}>
              <div style={{padding:30}}>
                <div style={{fontWeight:700,fontSize:32, marginBottom:40}}>Join the community</div>
                <div style={{fontWeight:500,fontSize:20}}>If you are a investor, builder, or community leader looking to help and expand your project with staking strategies or copperlaunch, welcome to join our community to learn more about vault.inc
                </div>
                <div style={{marginTop:30}}>
                  <a href="https://discord.com/invite/5eucBfYJrD" target={"_blank"}><Button color="primary">Join Discord</Button></a></div>
                  </div>
              </Grid>

              </Grid>
          </div>
       
           

        
          </Container>
          <div style={{background:"linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)", padding:"80px 0"}}>
              <Grid container spacing={2} style={{maxWidth:1200,margin:"0 auto"}}>
                <Grid item xs={12} md={3}/>
                {renderCommunity("Twitter",require("assets/img/twitter.png").default,"https://twitter.com/vaultinc_")}
                {renderCommunity("Discord",require("assets/img/discord.png").default,"https://discord.com/invite/5eucBfYJrD")}
                {/* {renderCommunity("Medium",require("assets/img/medium.png").default,"")} */}
                {renderCommunity("Github",require("assets/img/github.png").default,"https://github.com/vault-tec-team")}
              </Grid>
             </div>
    </>

  );
};


export default LandingPage;
