import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Notifier } from "features/common";
import appStyle from "./jss/appStyle.js";
import createAppTheme from "./jss/appTheme";
import LandingHeader from "./components/LandingHeader";
import { Container } from "@material-ui/core";
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = createAppTheme(true);

  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>

        <div
          style={{
            overflowX:"hidden",
            background:"linear-gradient(90deg, #0B1726 3.49%, #07090D 39.49%, #06070A 66.56%, #0B1726 96.28%)",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
       <LandingHeader />
          <div>
            {children}
            <Notifier />
          </div>
          {/* <Footer /> */}
        </div>

      </ThemeProvider>
    </StylesProvider>
  );
}
