import React from "react";
import LandingContent from "./components/LandingContent";
import createTheme from "../home/jss/appTheme";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const theme = createTheme(true);
  const { t } = useTranslation();

  return (
          <LandingContent />
  
  );
};



export default Landing;
