import { grayColor } from "assets/jss/material-kit-pro-react.js";
const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: `${theme.palette.text.green}`,
    color: `white`,
    textTransform: "none",
    // border: `1px solid ${theme.palette.text.green}`,
    height: "36px",

    fontWeight: 600,
    borderRadius: "25px",
    position: "relative",
    padding: "10px 30px",
    margin: 3,
    fontSize: "17px",
    letterSpacing: "0",
    // willChange: 'box-shadow, transform',
    // transition:
    //   'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    // touchAction: 'manipulation',
    cursor: "pointer",
    "& .MuiButton-label": {
      // color: `${theme.palette.text.green}`,
      fontWeight: 600,
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.green}`,
    color: `${theme.palette.text.green}`,
    "& .MuiButton-label": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
  blue: {
    boxShadow: "5px 5px 5px 1px rgba(0, 0, 0, 0.5)",
    minWidth: 180,
  },
  primary: {
    backgroundColor: "#2D9AEE",
    fontSize: "17px",
    marginRight:10,
    height: "41px",
    "& .MuiButton-label": {
      color: "#ffffff",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      backgroundColor: "#2D9AEE",
    },
  },
  secondary: {
    background: "linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)",
    margin: 5,
    minWidth: 280,
    fontSize: "24px",
    borderRadius: "8px",
    border:"1px solid white",
    height: "56px",
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "linear-gradient(216.83deg, #102540 0.16%, #0D1E35 46.22%, #0A070B 98.05%)",
    },
  },
  follow: {
    border: `4px solid #F6F6F6`,
    width:96,
    height:45,
    backgroundColor: "#848484",
    fontSize: "1rem",
    fontWeight: 900,
    "& .MuiButton-label": {
      color: "#F6F6F6",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#F6F6F6",
      },
      backgroundColor: "#848484",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    textDecoration: "none",
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    border: "none",
    backgroundColor: "rgb(221, 221, 221)",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "56px",
    borderRadius:8,
    margin:7,
    padding: "1.125rem 2.25rem",
    fontSize: "24px",
    lineHeight: "1.333333",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    padding: "0.3rem 0.5rem",
    height: 30,
    fontSize: "0.9rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: grayColor[0],
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
