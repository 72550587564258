import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import discordIcon from "assets/img/discord.png";
import mediemIcon from "assets/img/mediem.png";
import twitterIcon from "assets/img/twitter.png";
import styles from "./styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <div style={{ paddingBottom:100 ,backgroundColor:"#1F1F1F"}}>
       <Container style={{maxWidth:1100, margin:"0 auto", fontSize:21, lineHeight:1.5}} >
              <h1 className="subheader" style={{margin:"50px 0"}}>Vault.inc foundation</h1>
              <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
              <h1 className="subheader" style={{margin:"20px 0", textAlign:"left"}}>Get in touch</h1>
              <div>If you are a large token holder or validator, we’d love to partner with you and build together. </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                <h1 className="subheader" style={{margin:"20px 0", textAlign:"left"}}>Docs</h1>
                <div>whitepaper</div>
                <div>Token Economy</div>
                <div>governance</div>
                <div style={{marginTop:10}}>
                <a href="https://twitter.com/vaultinc_" target="_blank">
                  <img
                    src={twitterIcon}
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      objectFit: "contain",
                    }}
                  />
                </a>
                <a href="https://discord.com/invite/5eucBfYJrD" target="_blank">
                  <img
                    src={discordIcon}
                    style={{
                      width: "30px",
                      height: "30px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      objectFit: "contain",
                    }}
                  />{" "}
                </a>
                <a href="" target="_blank">
              <img
                src={mediemIcon}
                style={{
                  width: "35px",
                  height: "35px",
                  verticalAlign: "middle",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
              />{" "}
            </a>
                </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                <h1 className="subheader" style={{margin:"20px 0", textAlign:"left"}}>Products</h1>
                <div>Vault.inc</div>
                </Grid>
              </Grid>
             </Container>
    </div>;
};

export default memo(Footer);
